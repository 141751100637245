<template>
    <modal
      :name=modal_name
      :header_text="'Edit Trade'"
    >
      <section class="eff-modal-body">
        <slot name="body">
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-option-type">
              <ejs-dropdownlist
                  v-model="tradeData.option_type"
                  :dataSource="option_types"
                  floatLabelType="Always"
                  placeholder="Option Type">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-ticker">
              <ejs-textbox
                  v-model="tradeData.ticker_symbol"
                  floatLabelType="Always"
                  placeholder="Ticker"
                  style="text-transform:uppercase">
              </ejs-textbox>
            </div>
          </div>
          <div class="eff-modal-row-divider">
            <div class="eff-modal-cell-pad eff-trade-option-expiration">
              <ejs-datepicker
                  v-model="tradeData.expiration_date"
                  floatLabelType="Always"
                  placeholder="Expiration Date">
              </ejs-datepicker>
            </div>
            <div class="eff-modal-cell eff-trade-option-strike">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model="tradeData.strike_price"
                  floatLabelType="Always"
                  placeholder="Strike Price">
              </ejs-numerictextbox>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-option-action">
              <ejs-dropdownlist
                  v-model="tradeData.trade_action"
                  :dataSource="option_trade_map"
                  :fields="{ text: 'action', value: 'key' }"
                  floatLabelType="Always"
                  placeholder="Action">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-date-picker">
              <ejs-datepicker
                  v-model="tradeData.trade_date"
                  floatLabelType="Always"
                  placeholder="Trade Date">
              </ejs-datepicker>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-quantity">
              <ejs-numerictextbox
                  id="numeric"
                  :min=0
                  :showSpinButton=false
                  v-model="tradeData.trade_contracts"
                  floatLabelType="Always"
                  placeholder="Contracts">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell-pad eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min="0"
                  format="c2"
                  :showSpinButton=false
                  v-model="tradeData.trade_price"
                  floatLabelType="Always"
                  placeholder="Price">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell eff-trade-commission">
              <ejs-numerictextbox
                  id="currency"
                  :min="0"
                  format="c2"
                  :showSpinButton=false
                  v-model="tradeData.trade_commission"
                  floatLabelType="Always"
                  placeholder="Commission">
              </ejs-numerictextbox>
            </div>
          </div>
        </slot>
      </section>
      <div class="eff-modal-footer">
        <div class="eff-modal-footer-left">
          <button
              type="button"
              class="eff-btn-delete"
              @click="remove">
            Delete
          </button>
        </div>
        <div class="eff-modal-footer-right">
          <button
              type="button"
              class="eff-btn-blue"
              @click="save">
            Save
          </button>
          <button
              type="button"
              class="eff-btn-blue"
              @click="close">
            Cancel
          </button>
        </div>
      </div>
    </modal>
</template>

<script>

import Modal from '@/components/Modal'
import moment from "moment"
import axios from "axios";

export default {
  name: 'TradeEditOptionModal',
  props: {
    modal_name: String
  },
  emits: ['close-modal'],
  components: {
    Modal,
  },
  data() {
    return {
      option_types: ['Call', 'Put'],
      option_trade_map: [
        { key: 'Buy', action: 'Buy to Open' },
        { key: 'Short', action: 'Sell to Open' },
        { key: 'Sell', action: 'Sell to Close' },
        { key: 'Cover', action: 'Buy to Close' }
      ],
      tradeData: {}
    }
  },
  methods: {
    clearFields() {
      this.tradeData = {};
    },
    getTradeData(option_trade_uuid) {
      axios
          .get(process.env.VUE_APP_BASE_URL + '/optiontrades/getbyuuid', {
            params: { trade_uuid: option_trade_uuid }
          })
          .then(response => {
            this.tradeData = response.data;
            this.tradeData.option_trade_uuid = option_trade_uuid;
            this.tradeData.trade_date = moment(this.tradeData.trade_date.toLocaleString()).format("MM/DD/YYYY");
            this.tradeData.expiration_date = moment(this.tradeData.expiration_date.toLocaleString()).format("MM/DD/YYYY");
          })
    },
    save() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/optiontrades/update', {
            option_trade_uuid: this.tradeData.option_trade_uuid,
            trade_date: moment(this.tradeData.trade_date.toLocaleString()).format("MM/DD/YYYY"),
            trade_contracts: this.tradeData.trade_contracts,
            trade_action: this.tradeData.trade_action,
            trade_price: this.tradeData.trade_price,
            trade_commission: this.tradeData.trade_commission,
            trade_match_type: 'Tax Advantage'
          }).then(() => {
            this.close();
          }
      )
    },
    remove() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/optiontrades/delete', {
            option_trade_uuid: this.tradeData.option_trade_uuid,
            trade_match_type: 'Tax Advantage'
          }).then(() => {
            this.close();
          }
      )
    },
    close() {
      this.$emit("close-modal")
      this.clearFields();
      this.$store.dispatch('modals/close', this.$props['modal_name']);
    }
  }
};
</script>

<style>
@import '../assets/styles/trade.css';
</style>