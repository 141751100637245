<template>
  <div class="eff-dash-header">
    <ejs-dropdownlist id="accounts"
                      v-model="dto.account_id"
                      :dataSource="accounts"
                      :fields="{text:'account_name', value:'account_id'}"
                      placeholder="Accounts"
                      :index="0"
                      :change="getAccountData">
    </ejs-dropdownlist>
    <div style="display: flex; padding-top: 10px">
      <div style="padding-right: 15px">
        <div style="width: max-content">
          Balance
        </div>
        <div>
          Change
        </div>
      </div>
      <div style="padding-right: 30px; text-align: right">
        <div style="width: max-content">
          {{data['total_balance']}}
        </div>
        <div>
          {{data['total_change']}}
        </div>
      </div>
      <div style="padding-right: 15px">
        <div style="width: max-content">
          Stock
        </div>
        <div>
          Change
        </div>
      </div>
      <div style="padding-right: 30px; text-align: right">
        <div style="width: max-content">
          {{data['stock_balance']}}
        </div>
        <div>
          {{data['stock_change']}}
        </div>
      </div>
      <div style="padding-right: 15px">
        <div style="width: max-content">
          Option
        </div>
        <div>
          Change
        </div>
      </div>
      <div style="padding-right: 30px; text-align: right">
        <div style="width: max-content">
          {{data['option_balance']}}
        </div>
        <div>
          {{data['option_change']}}
        </div>
      </div>
      <div style="padding-right: 15px">
        <div style="width: max-content">
          Cash Balance
        </div>
      </div>
      <div style="padding-right: 30px; text-align: right">
        <div style="width: max-content">
          {{data['cash_balance']}}
        </div>
      </div>
    </div>
  </div>
  <div>
    <ejs-grid
        :dataSource="data['positions_list']"
        :enableHover="false"
    >
      <e-columns>
        <e-column field="ticker_symbol" headerText="Symbol" width="70"></e-column>
        <e-column field="description" headerText="Option Name" width="200"></e-column>
        <e-column field="trade_action" headerText="Action" width="75" textAlign="right"></e-column>
        <e-column field="quantity" headerText="Quantity" width="75" textAlign="right" :customAttributes="numColumn"></e-column>
        <e-column field="current_price" headerText="Current Price" width="80" textAlign="right" :customAttributes="numColumn"></e-column>
        <e-column field="price_change" headerText="Day Change" width="75" textAlign="right" :customAttributes="numColumn"></e-column>
        <e-column field="percent_change" headerText="% Change" width="75" textAlign="right" :customAttributes="numColumn"></e-column>
        <e-column field="value_change" headerText="Value Change" width="100" textAlign="right" :customAttributes="numColumn"></e-column>
        <e-column field="current_value" headerText="Value" width="100" textAlign="right" :customAttributes="numColumn"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>

import axios from 'axios'
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-vue-grids";


export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  data() {
    return {
      dto: {},
      data: null,
      accounts: this.$store.state.auth.user.accounts,
      numColumn: { class: 'numColumn' }
    };
  },
  created() {
    let account_id = this.$store.state.auth.user.accounts[0].account_id
      axios
          .get(process.env.VUE_APP_BASE_URL+'/accounts/getlist', {
            params: {account_id: account_id}
          })
          .then(response => (
              this.data = response.data
          ))
  },
  methods: {
    getAccountData() {
      axios
          .get(process.env.VUE_APP_BASE_URL+'/accounts/getlist', {
            params: { account_id: this.dto.account_id }
          })
          .then(response => (
              this.data = response.data
          ))
    }
  }
};
</script>


<style>
@import '../assets/styles/dashboard.css';
</style>