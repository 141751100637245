<template>
  <div style="
        display:flex;
        margin:25px 0 0 0;">
    <div style="width:250px; padding:5px">
      <ejs-dropdownlist
          id="accounts"
          v-model="dto.account_id"
          :dataSource="accounts"
          :fields="{text:'account_name', value:'account_id'}"
          placeholder="Accounts"
          :index="0"
          :change="getAccountData">
      </ejs-dropdownlist>
    </div>
    <div style="padding:5px;">
      <ejs-button
          v-on:click="open('trade-create')">
        Add
      </ejs-button>
    </div>
  </div>
  <div>
    <ejs-grid
        :dataSource=computeAllTrades
        :rowSelected="onRowSelected"
        :enableHover="false"
        :loadingIndicator="{ indicatorType: 'Spinner'}"
    >
      <e-columns>
        <e-column field="trade_date" headerText="Date" width="100"></e-column>
        <e-column field="ticker_symbol" headerText="Stock" width="65"></e-column>
        <e-column field="description" headerText="Description" width="180"></e-column>
        <e-column field="trade_action" headerText="Action" width="60"></e-column>
        <e-column field="trade_quantity" headerText="Quantity" width="60" textAlign="right"></e-column>
        <e-column field="trade_price" headerText="Price" width="70" textAlign="right"></e-column>
        <e-column field="trade_commission" headerText="Commission" width="70" textAlign="right"></e-column>
        <e-column field="trade_amount" headerText="Amount" width="70" textAlign="right"></e-column>
        <e-column field="trade_balance" headerText="Balance" width="70" textAlign="right"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
  <TradeCreateModal
      :selected_account_id=getSelectedAccount()
      :modal_name="'trade-create'"
      @close-modal="getAccountData"
  />
  <TradeEditStockModal
      ref="tradeEditStockModal"
      :modal_name="'trade-edit-stock'"
      @close-modal="getAccountData"
  />
  <TradeEditOptionModal
      ref="tradeEditOptionModal"
      :modal_name="'trade-edit-option'"
      @close-modal="getAccountData"
  />
  <TradeEditCashModal
      ref="tradeEditCashModal"
      :modal_name="'trade-edit-cash'"
      @close-modal="getAccountData"
  />
</template>

<script>

import axios from 'axios'
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-vue-grids";
import TradeEditStockModal from "./TradeEditStockModal.vue"
import TradeEditOptionModal from "./TradeEditOptionModal.vue"
import TradeEditCashModal from "./TradeEditCashModal.vue"
import TradeCreateModal from "@/views/TradeCreateModal";

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    TradeCreateModal,
    TradeEditStockModal,
    TradeEditOptionModal,
    TradeEditCashModal,
  },
  data() {
    return {
      dto: {},
      allTrades: {},
      accounts: this.$store.state.auth.user.accounts,
      isCreateVisible: false,
      isEditVisible: false,
      selectedRow: null,
      selectedTrade: null
    };
  },
  computed: {
    computeAllTrades() {
      return this.allTrades;
    }
  },
  created() {
    this.dto.account_id = this.$store.state.auth.user.accounts[0].account_id
      axios
          .get(process.env.VUE_APP_BASE_URL+'/transactions/getall', {
            params: {
              account_id: this.dto.account_id,
              last_num: 100
            }
          })
          .then(response => {
            this.allTrades = response.data;
          })
  },
  methods: {
    open(name) {
      this.$store.dispatch('modals/open', name)
    },
    getAccountData() {
      this.allTrades = {}
      axios
          .get(process.env.VUE_APP_BASE_URL+'/transactions/getall', {
            params: {
              account_id: this.dto.account_id,
              last_num: 100
            }
          })
          .then(response => {
            this.allTrades = response.data;
          })
    },
    getSelectedAccount() {
      return this.dto.account_id
    },
    onRowSelected(args) {
      this.selectedRow = args["data"];
      if (this.selectedRow != null) {
        let equity_uuid = '';
        let option_uuid = '';
        let cash_uuid = '';
        if (this.selectedRow["equity_trade_id"] > 0) {
          equity_uuid = this.selectedRow["trade_uuid"];
          this.open('trade-edit-stock');
          this.$refs.tradeEditStockModal.getTradeData(equity_uuid);
        }
        else if (this.selectedRow["option_trade_id"] > 0) {
          option_uuid = this.selectedRow["trade_uuid"];
          this.open('trade-edit-option');
          this.$refs.tradeEditOptionModal.getTradeData(option_uuid);
        }
        else {
          cash_uuid = this.selectedRow["trade_uuid"];
          this.open('trade-edit-cash');
          this.$refs.tradeEditCashModal.getTradeData(cash_uuid);
        }
      }
    }
  }
};
</script>