<template>
  <meta name="theme-color" content="#EC6A13"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
  <app-nav />
  <div id="main-app">
    <div id="main-viewport">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppNav from './components/AppNav'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    AppNav
  },
  mounted() {
    document.title = "effio finance";
  },
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('auth/SET_USER_DATA', userData)
    }
    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response.status === 401) {
            this.$store.commit('auth/CLEAR_USER_DATA');
            this.$router.push({name: 'home'});
          }
          return Promise.reject(error);
        }
    )
  }
}
</script>

<style>
@font-face {
  font-family: "CourierPrime";
  src: local("Sans-Serif"),
  url(./assets/font/CourierPrime-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Rubik";
  src: local("Sans-Serif"),
  url(./assets/font/Rubik-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Days One";
  src: local("Sans-Serif"),
  url(./assets/font/DaysOne-Regular.ttf) format("truetype");
}

.mouse_over_pointer {
  cursor: pointer
}
.e-grid .e-headercell {
  background-color: #40A2D9;
  color: white
}
.e-btn {
  background-color: #40A2D9;
  border-color: #40A2D9;
}
.e-btn:hover {
  background-color: #40A2D9;
}
.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
    background-color: #40A2D9;
    color: white;
}
@import './assets/styles/global.css';
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-layouts/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-navigations/styles/bootstrap5.css';
</style>
