<template>
  <div id="nav" ref="root">
    <div class="eff-nav-bars" v-if="signedIn">
      <div v-on:click="hamburger_open = !hamburger_open">
        <div class="eff-nav-bars-button" v-if="!hamburger_open">
          <img id="hamburger-icon" class="eff-nav-bars-image" alt="hamburger menu" :src="hamburgerMenu" />
        </div>
        <div class="eff-nav-bars-button" v-if="hamburger_open">
          <img id="hamburger-icon" class="eff-nav-bars-image" alt="x menu" :src="xMark" />
        </div>
      </div>
      <div class="eff-nav-bars-menu" v-if="hamburger_open">
        <div class="eff-nav-bars-menu-items" v-on:click="sendToRoute($event)">
          <img id="nav-fav" class="eff-nav-bars-menu-image" alt="favorites icon" :src="priceTag"/>
          <span id="nav-fav">Favorites</span>
        </div>
        <div class="eff-nav-bars-menu-items" v-on:click="sendToRoute($event)">
          <img id="nav-dash" class="eff-nav-bars-menu-image" alt="dashboard icon" :src="tableList"/>
          <span id="nav-dash">Dashboard</span>
        </div>
        <div class="eff-nav-bars-menu-items" v-on:click="sendToRoute($event)">
          <img id="nav-trade" class="eff-nav-bars-menu-image" alt="trade icon" :src="rightLeft"/>
          <span id="nav-trade">Trades</span>
        </div>
        <div class="eff-nav-bars-menu-divider"></div>
        <div class="eff-nav-bars-menu-items" v-on:click="sendToRoute($event)">
          <img id="nav-signout" class="eff-nav-bars-menu-image" alt="sign out" :src="signOut"/>
          <span id="nav-signout">Sign Out</span>
        </div>
      </div>
    </div>
    <div id="nav-logo" class="eff-logo" v-on:click="sendToRoute($event)">
      <img class="effio-logo" alt="effio logo" :src="effioLogo"/>
    </div>
    <div class="eff-nav-menu">
      <div class="eff-nav-menu-actions" v-if="signedIn">
        <div id="nav-fav" class="eff-nav-menu-button" v-on:click="sendToRoute($event)">
          Favorites
        </div>
        <div id="nav-dash" class="eff-nav-menu-button" v-on:click="sendToRoute($event)">
          Dashboard
        </div>
        <div id="nav-trade" class="eff-nav-menu-button" v-on:click="sendToRoute($event)">
          Trades
        </div>
      </div>
      <div class="eff-nav-menu-signin">
        <div class="eff-nav-menu-button" v-if="!signedIn">
          <router-link style="text-decoration: none; color: inherit" to="/signin">Sign In</router-link>
        </div>
      </div>
      <div class="eff-nav-right">
        <div class="eff-ticker-search" v-if="signedIn">
          <ejs-textbox id="nav-quote"
              placeholder="Search"
              v-model="symbol_value"
              v-on:keyup.enter="sendToRoute($event)"
              floatLabelType="Never"
              style="text-transform:uppercase"/>
        </div>
        <div class="eff-nav-account" v-if="signedIn">
          <div class="eff-nav-account-icon" v-on:click="account_menu_open = !account_menu_open">
            <img class="eff-nav-account-image" alt="account icon" :src="accountIcon"/>
            <img class="eff-nav-account-arrow" alt="down arrow" :src="accountArrow"/>
          </div>
          <div class="eff-nav-account-menu" v-if="account_menu_open">
            <div class="eff-nav-account-menu-items" v-on:click="sendToRoute($event)">
              <img id="nav-account" class="eff-nav-account-menu-image" alt="edit account" :src="editAccount"/>
              <span id="nav-account" class="eff-nav-account-menu-text">My Account</span>
            </div>
            <div class="eff-nav-account-menu-items" v-on:click="sendToRoute($event)">
              <img id="nav-signout" class="eff-nav-account-menu-image" alt="sign out" :src="signOut"/>
              <span id="nav-signout" class="eff-nav-account-menu-text">Sign Out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers";
import effioLogo from "@/assets/img/effio_logo.svg"
import hamburgerMenu from "@/assets/img/bars-solid.svg";
import xMark from "@/assets/img/xmark-solid.svg";
import priceTag from "@/assets/img/price-tag.svg";
import rightLeft from "@/assets/img/arrow-right-left.svg";
import tableList from "@/assets/img/table-list.svg";
import signOut from "@/assets/img/arrow-exit.svg";
import accountIcon from "@/assets/img/circle-user.svg";
import accountArrow from "@/assets/img/chevron-down.svg";
import editAccount from "@/assets/img/user-pen.svg";

export default {
  computed: {
    ...authComputed
  },
  data () {
    return {
      xMark,
      hamburgerMenu,
      effioLogo,
      priceTag,
      rightLeft,
      tableList,
      signOut,
      accountIcon,
      accountArrow,
      editAccount,
      symbol_value: '',
      hamburger_open: false,
      account_menu_open: false
    };
  },
  methods: {
    sendToRoute(event) {
      this.hamburger_open = false;
      this.account_menu_open = false;

      console.log(event.target)

      if (event.target.id === 'nav-dash') {
        this.$router.push({name: 'dashboard'})
      }
      else if (event.target.id === 'nav-trade') {
        this.$router.push({name: 'trades'})
      }
      else if (event.target.id === 'nav-quote') {
        this.$router.push({
          name: 'quote',
          params: {
            ticker_symbol: this.symbol_value
          }
        }).then(() => {
          this.symbol_value = ''
        })
      }
      else if (event.target.id === 'nav-signout') {
        this.$store
          .dispatch('auth/signout')
          .then(() => {
            this.$router.push({
              name: 'home'
            })
          })
      }
      else {
        this.$router.push({name: 'home'})
      }
    },
    closeMenu(event) {
      let accountMenuSelected = this.$el.querySelector(".eff-nav-account")
      let hamburgerMenuSelected = this.$el.querySelector(".eff-nav-bars")

      if (event.target.id !== "hamburger-icon") {
        if (accountMenuSelected && !accountMenuSelected.contains(event.target)) {
          this.account_menu_open = false;
        }
        if (hamburgerMenuSelected && !hamburgerMenuSelected.contains(event.target)) {
          this.hamburger_open = false;
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu)
  }
}
</script>


<style>
@import '../assets/styles/appnav.css';
</style>