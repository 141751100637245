<template>
  <div style="
        display:flex;
        justify-content:center;
        margin:25px 0 0 0;">
    <div style="border-style:solid; border-width:1px; border-color:grey; border-radius:5px; padding:10px">
      Sign In
      <ejs-textbox
          v-model:value="emailAddress"
          type="email"
          floatLabelType="Always"
          placeholder="Email">
      </ejs-textbox>
      <ejs-textbox
          v-model:value="password"
          type="password"
          floatLabelType="Always"
          placeholder="Password">
      </ejs-textbox>

      <div class="e-float-input e-control-wrapper" style="display:flex; justify-content:center">
        <ejs-button
          v-on:click="signin">
          Sign in
        </ejs-button>
      </div>

      <p>{{ error }}</p>
      <router-link to="/signup">
          Don't have an account? Register.
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      emailAddress: '',
      password: '',
      error: null
    }
  },
  methods:{
    signin () {
      this.$store
          .dispatch('auth/signin', {
            email_address: this.emailAddress,
            password: this.password
          })
          .then(() => {
            this.$router.push({ name: 'dashboard' })
          })
          .catch(err => {
            this.error = err.response.data.error
          })
    }
  }
}
</script>

<style scoped>
</style>
