<template>
  <div
    v-if="isOpen"
    class="eff-modal-backdrop"
  >
    <div class="eff-modal">
      <header class="eff-modal-header">
        <slot name="header">
          {{ this.header_text }}
        </slot>
      </header>
      <slot :close="close"></slot>
    </div>
  </div>
</template>

<script>
  import xMark from "@/assets/img/xmark-solid.svg";

  export default {
    name: "Modal",
    props: {
      name: { type: String, required: true},
      header_text: {type:String, required: true}
    },
    data () {
     return {
       xMark,
     }
    },
    computed: {
      isActive() {
        return this.$store.getters['modals/active'] === this.name
      },
      isOpen() {
        return this.$store.getters['modals/allOpen'].includes(this.name)
      }
    },
    methods: {
      close() {
        this.$store.dispatch('modals/close', this.name)
      }
    },
    beforeUnmount() {
      if (this.isOpen) {
        this.close()
      }
    }
  }
</script>

<style>
@import '../assets/styles/modal.css';
</style>