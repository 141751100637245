<template>
  <div>
    <form @submit.prevent="register">

      <label for="email">
        Email:
      </label>
      <input v-model="email" type="email" name="email" value>

      <label for="password">
        Password:
      </label>
      <input v-model="password" type="password" name="password" value>

      <label for="password">
        Password:
      </label>
      <input v-model="confirmpassword" type="password" name="confirm password" value>

      <button type="submit" name="button">
        Register
      </button>
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
      <router-link to="/signin">
        Already have an account? Sign In.
      </router-link>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      confirmpassword: '',
      errors: null
    }
  },
  methods: {
    register () {
      this.$store
        .dispatch('auth/signup', {
          email_address: this.email,
          new_password1: this.password,
          new_password2: this.confirmpassword
        })
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
      .catch(err => {
        this.errors = err.response.data.error
      })
    }
  }
}
</script>

<style scoped>
</style>
