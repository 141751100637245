<template>
    <modal
      :name=modal_name
      :header_text="'Edit Trade'"
    >
      <section class="eff-modal-body">
        <slot name="body">
          <div class="eff-modal-row-divider">
            <div class="eff-modal-cell-pad eff-trade-ticker">
              <ejs-textbox
                  v-model="tradeData.ticker_symbol"
                  floatLabelType="Always"
                  placeholder="Ticker"
                  style="text-transform:uppercase">
              </ejs-textbox>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-stock-action">
              <ejs-dropdownlist
                  v-model="tradeData.adjustment_type"
                  :dataSource="cash_types"
                  floatLabelType="Always"
                  placeholder="Action">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-date-picker">
              <ejs-datepicker
                  v-model:value="tradeData.adjustment_date"
                  floatLabelType="Always"
                  placeholder="Date">
              </ejs-datepicker>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min="0"
                  format="c2"
                  :showSpinButton=false
                  v-model:value="tradeData.adjustment_value"
                  floatLabelType="Always"
                  placeholder="Amount">
              </ejs-numerictextbox>
            </div>
          </div>
        </slot>
      </section>
      <div class="eff-modal-footer">
        <div class="eff-modal-footer-left">
          <button
              type="button"
              class="eff-btn-delete"
              @click="remove">
            Delete
          </button>
        </div>
        <div class="eff-modal-footer-right">
          <button
              type="button"
              class="eff-btn-blue"
              @click="save">
            Save
          </button>
          <button
              type="button"
              class="eff-btn-blue"
              @click="close">
            Cancel
          </button>
        </div>
      </div>
    </modal>
</template>

<script>

import Modal from '@/components/Modal'
import axios from "axios";
import moment from "moment"

export default {
  name: 'TradeEditCashModal',
  props: {
    modal_name: String
  },
  emits: ['close-modal'],
  components: {
    Modal,
  },
  data() {
    return {
      cash_types: ['Deposit', 'Withdrawal', 'Interest Accrued', 'Interest Paid', 'Fee', 'Fee Adjust', 'Dividend', 'Equity Adjust', 'Rollover'],
      tradeData: {}
    }
  },
  methods: {
    clearFields() {
      this.tradeData = {};
    },
    getTradeData(cash_trade_uuid) {
      axios
          .get(process.env.VUE_APP_BASE_URL + '/cashtrades/getbyuuid', {
            params: { trade_uuid: cash_trade_uuid }
          })
          .then(response => {
            this.tradeData = response.data;
            this.tradeData.cash_trade_uuid = cash_trade_uuid;
            this.tradeData.adjustment_date = moment(this.tradeData.adjustment_date.toLocaleString()).format("MM/DD/YYYY");
          })
    },
    save() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/cashtrades/update', {
            cash_trade_uuid: this.tradeData.cash_trade_uuid,
            adjustment_date: moment(this.tradeData.adjustment_date.toLocaleString()).format("MM/DD/YYYY"),
            adjustment_type: this.tradeData.adjustment_type,
            adjustment_value: this.tradeData.adjustment_value,
          }).then(() => {
            this.close();
          }
      )
    },
    remove() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/cashtrades/delete', {
            cash_trade_uuid: this.tradeData.cash_trade_uuid,
          }).then(() => {
            this.close();
          }
      )
    },
    close() {
      this.$emit("close-modal")
      this.clearFields();
      this.$store.dispatch('modals/close', this.$props['modal_name']);
    }
  }
};
</script>

<style>
@import '../assets/styles/trade.css';
</style>