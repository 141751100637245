import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import {registerLicense} from "@syncfusion/ej2-base";
import {ButtonPlugin, RadioButtonPlugin} from "@syncfusion/ej2-vue-buttons";
import {NumericTextBoxPlugin, TextBoxPlugin} from "@syncfusion/ej2-vue-inputs";
import {DropDownListPlugin, ComboBoxPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DatePickerPlugin} from "@syncfusion/ej2-vue-calendars";

registerLicense('Mgo+DSMBaFt/QHRqVVhkXVpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9iS3xad0NhWn5ZdnJUQA==;Mgo+DSMBPh8sVXJ0S0J+XE9Ad1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xSdEdqWHlcc3VSQGdaVw==;ORg4AjUWIQA/Gnt2VVhkQlFacl5JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhUH5YcndUQWZVU0I=;ODQwMTIzQDMyMzAyZTM0MmUzMG5YSDYvWlJhbEcxUFJ3WVlqZmpXK1FJdzhmUDFHNkhnTnQ5UGxyeG1SRG89;ODQwMTI0QDMyMzAyZTM0MmUzMGcxSlNZQmsxb1VHc2hpU1FqNXZ3ampRTkNKK0ZrUndwVVdITmMwZmk4S1U9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtDVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdERhW3ZfdnZWR2dbV0Nw;ODQwMTI2QDMyMzAyZTM0MmUzMGsxZ2kxdk1SUFFHRVpTMU1NbkhHdUcvQjBOb0d2SnBZYThCVUtuR2E0Vk09;ODQwMTI3QDMyMzAyZTM0MmUzMG9Ici9FMWZZODNlUCtWT1d6QkZ5a09wZEoyVDFWMGM3T2VidFRJbkhpbDA9;Mgo+DSMBMAY9C3t2VVhkQlFacl5JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhUH5YcndUQWhYUUI=;ODQwMTI5QDMyMzAyZTM0MmUzMEpmZHhIdVBBZXlVblo1ajZ3ZEdZZnNhNGhEcjFKak15WnY2VmtNK2FyMDg9;ODQwMTMwQDMyMzAyZTM0MmUzMGtaSHRUMXhiajJFb2oyS1JzcG91NklWVjViajBhMWlaOWk2RVBCMjc5ZWs9;ODQwMTMxQDMyMzAyZTM0MmUzMGsxZ2kxdk1SUFFHRVpTMU1NbkhHdUcvQjBOb0d2SnBZYThCVUtuR2E0Vk09')
createApp(App)
    .use(router)
    .use(store)
    .use(ButtonPlugin)
    .use(RadioButtonPlugin)
    .use(TextBoxPlugin)
    .use(NumericTextBoxPlugin)
    .use(DropDownListPlugin)
    .use(ComboBoxPlugin)
    .use(DatePickerPlugin)
    .mount('#app')
