import axios from 'axios'

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        SET_USER_DATA(state, userData) {
            state.user = userData
            localStorage.setItem('user', JSON.stringify(userData))
            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.auth_token}`
        },
        CLEAR_USER_DATA(state) {
            state.user = null
            localStorage.removeItem('user')
            axios.defaults.headers.common['Authorization'] = null
        }
    },
    actions: {
        signup({commit}, credentials) {
            return axios
                .post(process.env.VUE_APP_BASE_URL + '/users/create', credentials)
                .then(({data}) => {
                    commit('SET_USER_DATA', data)
                })
        },
        signin({commit}, credentials) {
            return axios
                .post(process.env.VUE_APP_BASE_URL + '/auth/signin', credentials)
                .then(({data}) => {
                    commit('SET_USER_DATA', data)
                })
        },
        signin_with_auth({commit}, credentials) {
            return axios
                .post(process.env.VUE_APP_BASE_URL + '/auth/signinwithauth', credentials)
                .then(({data}) => {
                    commit('SET_USER_DATA', data)
                })
                .catch(() => {
                    commit('CLEAR_USER_DATA')
                })
        },
        signout({commit}) {
            let user = localStorage.getItem('user')
            axios
                .post(process.env.VUE_APP_BASE_URL + '/auth/signout', user)
                .then(() => {
                    commit('CLEAR_USER_DATA')
                })
        }
    },
    getters: {
        signedIn(state) {
            return !!state.user
        }
    }
}