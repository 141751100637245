import { createRouter, createWebHistory} from 'vue-router'
import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'
import Quote from './views/Quote.vue'
import Trade from './views/Trade.vue'
import SignUp from './views/SignUp.vue'
import SignIn from './views/SignIn.vue'
import store from './vuex/store.js'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { fromHome: true }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/quote/:ticker_symbol',
        name: 'quote',
        component: Quote,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/trades',
        name: 'trades',
        component: Trade,
        meta: { requiresAuth: true }
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignUp
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const signedIn = localStorage.getItem('user')
    if (to.matched.some(record => record.meta.fromHome) && signedIn) {
        let userDate = JSON.parse(signedIn)
        store.dispatch("auth/signin_with_auth", {
            auth_token: userDate.auth_token
        }).then(() => {
            next()
        })
    } else if (to.matched.some(record => record.meta.requiresAuth) && !signedIn) {
        next({name: 'home'})
    } else {
        next()
    }
})

export default router