<template>
  <div class="home" style="font-family:'Days One', sans-serif">
    <h1>Welcome to the App</h1>
    <div class="div-hide">
      Hello World!
    </div>
    <div v-if="!signedIn">
      To use this app you'll need to
      <router-link to="/signin">
        Sign In
      </router-link>
      or
      <router-link to="/signup">
        Sign Up
      </router-link>
    </div>
  </div>
</template>

<script>
import { authComputed } from "../vuex/helpers";

export default {
  computed: {
    ...authComputed
  }
}
</script>