<template>
  <div class="eff-company" v-if="!!data && !error">
    <div class="eff-company-name">
      <div class="eff-company-name-long">
        {{data.long_name}}
      </div>
      <div class="eff-company-details">
        <div class="eff-company-item eff-company-item-start">
          <div class="eff-company-item-label">
            Symbol
          </div>
          <div class="eff-company-item-value">
            {{data.ticker_symbol}}
          </div>
        </div>
        <div class="eff-company-item">
          <div class="eff-company-item-label">
            Type
          </div>
          <div class="eff-company-item-value">
            {{data.stock_type}}
          </div>
        </div>
        <div class="eff-company-item">
          <div class="eff-company-item-label">
            Exchange
          </div>
          <div class="eff-company-item-value">
            {{data.primary_exchange}}
          </div>
        </div>
        <div class="eff-company-item">
          <div class="eff-company-item-label">
            Currency
          </div>
          <div class="eff-company-item-value">
            {{data.currency_type}}
          </div>
        </div>
      </div>
    </div>
    <div class="eff-price">
      <div class="eff-price-current">
        <span class="eff-price-current-price">
          {{data.last_trade_price}}
        </span>
        <span class="eff-price-current-value">
          {{data.last_change}}
        </span>
        <span class="eff-price-current-value">
          {{data.last_change_percent}}
        </span>
      </div>
      <div class="eff-price-after-container">
        <div v-if="!data.is_market_open" class="eff-price-after">
          <div class="eff-price-after-label">
            After Hours:
          </div>
          <div>
            <span class="eff-price-after-price">
              {{data.post_market_price}}
            </span>
            <span class="eff-price-after-value">
              {{data.post_change}}
            </span>
            <span class="eff-price-after-value">
              {{data.post_change_percent}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.is_market_open" class="eff-trade-date">
      <span>
        Last Trade:
      </span>
      <span class="eff-trade-date-text">
        {{formatDate(data.last_tradeutc)}}
      </span>
    </div>
    <div v-else class="eff-trade-date">
      <span>
        Closed:
      </span>
      <span class="eff-trade-date-text">
        {{formatDate(data.post_market_tradeutc)}}
      </span>
    </div>
    <div class="eff-market-cap-volume">
      <div class="eff-market-cap">
        <span>
          Market Cap:
        </span>
        <span class="eff-market-cap-value">
          {{data.market_cap}}
        </span>
      </div>
      <div class="eff-volume">
        <div class="eff-volume-column">
          <div class="eff-volume-label">
            Volume
          </div>
          <div class="eff-volume-value">
            {{data.day_volume}}
          </div>
        </div>
        <div class="eff-volume-column">
          <div class="eff-volume-label">
            10 day avg.
          </div>
          <div class="eff-volume-value">
            {{data.day_volume_average_10_days}}
          </div>
        </div>
        <div class="eff-volume-column">
          <div class="eff-volume-label">
            3 mos avg.
          </div>
          <div class="eff-volume-value">
            {{data.day_volume_average_3_months}}
          </div>
        </div>
      </div>
    </div>
    <div class="eff-stock-stats">
      <div class="eff-price-ranges">
        <div class="eff-price-row eff-price-header-row">
          <div class="eff-price-header-label">
            Ranges
          </div>
          <div class="eff-price-value-text">
            <div class="eff-price-header-price">
              Price
            </div>
            <div class="eff-price-header-percent">
              (% to current price)
            </div>
          </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-price-label-text">
            Day Range High
          </div>
          <div class="eff-price-value-text">
            <div>
              {{data.day_high_price}}
            </div>
            <div class="eff-price-value-percent">
              ({{data.day_high_percent}})
            </div>
          </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-price-label-text">
            Day Range Low
          </div>
          <div class="eff-price-value-text">
          <div>
            {{data.day_low_price}}
          </div>
          <div class="eff-price-value-percent">
            ({{data.day_low_percent}})
          </div>
        </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-price-label-text">
            52 Week High
          </div>
          <div class="eff-price-value-text">
          <div>
            {{data.fiftytwo_high_price}}
          </div>
          <div class="eff-price-value-percent">
            ({{data.fiftytwo_high_percent}})
          </div>
        </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-price-label-text">
            52 Week Low
          </div>
          <div class="eff-price-value-text">
          <div>
            {{data.fiftytwo_low_price}}
          </div>
          <div class="eff-price-value-percent">
            ({{data.fiftytwo_low_percent}})
          </div>
        </div>
        </div>
        <div class="eff-price-row-bottom">
          <div>
          Previous Close
          </div>
          <div class="eff-price-value-text">
            {{data.previous_close_price}}
          </div>
        </div>
      </div>
      <div class="eff-stock-info">
        <div class="eff-price-row eff-price-header-row">
          <div class="eff-info-header-label">
            Statistics
          </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-stock-info-label-text">
            PE Ratio
          </div>
          <div class="eff-stock-info-value-text">
            {{data.pe}}
          </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-stock-info-label-text">
            EPS
          </div>
          <div class="eff-stock-info-value-text">
            {{data.eps_trailing}}
          </div>
        </div>
        <div class="eff-price-row">
          <div v-if="data.earnings_date_next" class="eff-stock-info-label-text">
            Earnings Date
          </div>
          <div v-else class="eff-stock-info-label-text">
            Earnings Date Est.
          </div>
          <div v-if="data.earnings_date_next" class="eff-stock-info-value-text">
            {{data.earnings_date_next}}
          </div>
          <div v-else class="eff-stock-info-value-text eff-stock-info-value-text-dates">
            {{data.earnings_date_next_start}} - {{data.earnings_date_next_end}}
          </div>
        </div>
        <div class="eff-price-row">
          <div class="eff-stock-info-label-text">
            Dividend & Yield
          </div>
          <div v-if="data.dividend_rate_trailing === 'N/A'" class="eff-stock-info-value-text">
            {{data.dividend_rate_trailing}}
          </div>
          <div v-else class="eff-stock-info-value-text">
            {{data.dividend_rate_trailing}} ({{data.dividend_yield}})
          </div>
        </div>
        <div class="eff-price-row-bottom">
          <div>
            Dividend Date
          </div>
          <div class="eff-stock-info-value-text-end">
            {{data.dividend_date}}
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex; font-size:12px; padding-top:5px">
      <span>
        Price Updated
      </span>
      <span style="padding-left:5px">
        {{formatDate(data.updatedutc)}}
      </span>
    </div>
  </div>
  <div v-if="!!error">
    <h4>{{error}}</h4>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'quote',
  props: ['ticker_symbol'],
  data() {
    return {
      data: null,
      error: null
    };
  },
  watch: {
    ticker_symbol: function () {
      this.getQuote();
    }
  },
  mounted() {
    this.getQuote();
  },
  methods: {
    getQuote() {
      this.data = null
      this.error = null
      axios
          .get(process.env.VUE_APP_BASE_URL + '/markets/getquote', {
            params: {ticker_symbol: this.ticker_symbol}
          })
          .then(response => {
            if (response) {
              this.data = response.data
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              this.error = err.response.data.message
            }
          })
    },
    formatDate(datetime) {
      return moment(datetime).local().format('M/D/YYYY hh:mm a')
    }
  }
}
</script>


<style>
@import '../assets/styles/quote.css';
</style>