<template>
    <modal
      :name=modal_name
      :header_text="'Edit Trade'"
    >
      <section class="eff-modal-body">
        <slot name="body">
          <div class="eff-modal-row-divider">
            <div class="eff-modal-cell-pad eff-trade-ticker">
              <ejs-textbox
                  v-model="tradeData.ticker_symbol"
                  floatLabelType="Always"
                  placeholder="Ticker"
                  style="text-transform:uppercase">
              </ejs-textbox>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-stock-action">
              <ejs-dropdownlist
                  v-model="tradeData.trade_action"
                  :dataSource="stock_trade_type"
                  floatLabelType="Always"
                  placeholder="Action">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-date-picker">
              <ejs-datepicker
                  v-model:value="tradeData.trade_date"
                  floatLabelType="Always"
                  placeholder="Trade Date">
              </ejs-datepicker>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-quantity">
              <ejs-numerictextbox
                  id="numeric"
                  :min=0
                  :showSpinButton=false
                  v-model="tradeData.trade_quantity"
                  floatLabelType="Always"
                  placeholder="Quantity">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell-pad eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min="0"
                  format="c2"
                  :showSpinButton=false
                  v-model:value="tradeData.trade_price"
                  floatLabelType="Always"
                  placeholder="Price">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell eff-trade-commission">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  :showSpinButton=false
                  format="c2"
                  v-model:value="tradeData.trade_commission"
                  floatLabelType="Always"
                  placeholder="Commission">
              </ejs-numerictextbox>
            </div>
          </div>
        </slot>
      </section>
      <div class="eff-modal-footer">
        <div class="eff-modal-footer-left">
          <button
              type="button"
              class="eff-btn-delete"
              @click="remove">
            Delete
          </button>
        </div>
        <div class="eff-modal-footer-right">
          <button
              type="button"
              class="eff-btn-blue"
              @click="save">
            Save
          </button>
          <button
              type="button"
              class="eff-btn-blue"
              @click="close">
            Cancel
          </button>
        </div>
      </div>
    </modal>
</template>

<script>

import Modal from '@/components/Modal'
import axios from "axios";
import moment from "moment"

export default {
  name: 'TradeEditStockModal',
  props: {
    modal_name: String
  },
  emits: ['close-modal'],
  components: {
    Modal,
  },
  data() {
    return {
      stock_trade_type: ['Buy', 'Sell'],
      tradeData: {}
    }
  },
  methods: {
    clearFields() {
      this.tradeData = {};
    },
    getTradeData(equity_trade_uuid) {
      axios
          .get(process.env.VUE_APP_BASE_URL + '/stocktrades/getbyuuid', {
            params: { trade_uuid: equity_trade_uuid }
          })
          .then(response => {
            this.tradeData = response.data;
            this.tradeData.equity_trade_uuid = equity_trade_uuid;
            this.tradeData.trade_date = moment(this.tradeData.trade_date.toLocaleString()).format("MM/DD/YYYY");
          })
    },
    save() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/stocktrades/update', {
            equity_trade_uuid: this.tradeData.equity_trade_uuid,
            trade_date: moment(this.tradeData.trade_date.toLocaleString()).format("MM/DD/YYYY"),
            trade_quantity: this.tradeData.trade_quantity,
            trade_action: this.tradeData.trade_action,
            trade_price: this.tradeData.trade_price,
            trade_commission: this.tradeData.trade_commission,
            trade_match_type: 'Tax Advantage'
          }).then(() => {
            this.close();
          }
      )
    },
    remove() {
      axios
          .post(process.env.VUE_APP_BASE_URL+'/stocktrades/delete', {
            equity_trade_uuid: this.tradeData.equity_trade_uuid,
            trade_match_type: 'Tax Advantage'
          }).then(() => {
            this.close();
          }
      )
    },
    close() {
      this.$emit("close-modal")
      this.clearFields();
      this.$store.dispatch('modals/close', this.$props['modal_name']);
    }
  }
};
</script>

<style>
@import '../assets/styles/trade.css';
</style>