<template>
  <modal
      :name=modal_name
      :header_text="'Add Trade'"
  >
    <section class="eff-modal-body">
      <slot name="body">
        <div id="stocks" style="display: none" class="eff-modal-tab-section">
          <div class="eff-modal-row-divider">
            <div class="eff-modal-cell eff-trade-ticker">
              <ejs-textbox
                  v-model:value="ticker_symbol"
                  floatLabelType="Always"
                  placeholder="Ticker"
                  style="text-transform: uppercase"
                  @blur="lookupSymbol">
              </ejs-textbox>
            </div>
            <div v-if="ticker_found" class="eff-trade-ticker-checkmark">
              <img alt="check" :src="checkMark" style="height: 20px">
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-stock-action">
              <ejs-dropdownlist
                v-model="trade_action"
                :dataSource="stock_trade_types"
                floatLabelType="Always"
                placeholder="Action">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-date-picker">
              <ejs-datepicker
                  v-model:value="trade_date"
                  floatLabelType="Always"
                  placeholder="Trade Date">
              </ejs-datepicker>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-quantity">
              <ejs-numerictextbox
                  id="numeric"
                  :min=0
                  :showSpinButton=false
                  v-model:value="trade_quantity"
                  floatLabelType="Always"
                  placeholder="Quantity">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell-pad eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model:value="trade_price"
                  floatLabelType="Always"
                  placeholder="Trade Price">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell eff-trade-commission">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model:value="trade_commission"
                  floatLabelType="Always"
                  placeholder="Commission">
              </ejs-numerictextbox>
            </div>
          </div>
        </div>
        <div id="options" style="display: none" class="eff-modal-tab-section">
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-option-type">
              <ejs-dropdownlist
                v-model="option_type"
                :dataSource="option_types"
                floatLabelType="Always"
                placeholder="Type"
                :change="getOptionDates">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-ticker">
              <ejs-textbox
                  v-model:value="ticker_symbol"
                  floatLabelType="Always"
                  placeholder="Ticker"
                  style="text-transform:uppercase"
                  @blur="lookupSymbol">
              </ejs-textbox>
            </div>
            <div v-if="ticker_found" class="eff-trade-ticker-checkmark">
              <img alt="check" :src="checkMark" style="height:20px"/>
            </div>
          </div>
          <div class="eff-modal-row-divider">
            <div class="eff-modal-cell-pad eff-trade-option-expiration">
              <ejs-combobox
                  v-model:value="option_expiration_date"
                  :dataSource="option_expiration_dates"
                  :fields="{ text:'expiration_date', value:'expiration_date' }"
                  floatLabelType="Always"
                  placeholder="Expiration Date"
                  :change="getStrikePrices">
              </ejs-combobox>
            </div>
            <div class="eff-modal-cell eff-trade-option-strike">
              <ejs-combobox
                  v-model:value="option_strike_price"
                  :dataSource="option_strike_prices"
                  :fields="{ text:'strike_price', value:'strike_price' }"
                  floatLabelType="Always"
                  placeholder="Strike Price"
                  :change="lookupOption">
              </ejs-combobox>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-option-action">
              <ejs-dropdownlist
                v-model="trade_action"
                :dataSource="option_trade_map"
                :fields="{ text: 'action', value: 'key' }"
                floatLabelType="Always"
                placeholder="Action">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-date-picker">
              <ejs-datepicker
                  v-model:value="trade_date"
                  floatLabelType="Always"
                  placeholder="Trade Date">
              </ejs-datepicker>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-quantity">
              <ejs-numerictextbox
                  id="numeric"
                  :min=0
                  :showSpinButton=false
                  v-model:value="trade_quantity"
                  floatLabelType="Always"
                  placeholder="Contracts">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell-pad eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model:value="trade_price"
                  floatLabelType="Always"
                  placeholder="Trade Price">
              </ejs-numerictextbox>
            </div>
            <div class="eff-modal-cell eff-trade-commission">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model:value="trade_commission"
                  floatLabelType="Always"
                  placeholder="Commission">
              </ejs-numerictextbox>
            </div>
          </div>
        </div>
        <div id="cash" style="display: none" class="eff-modal-tab-section">
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-cash-action">
              <ejs-dropdownlist
                  v-model:value="cash_type"
                  :dataSource="cash_types"
                  floatLabelType="Always"
                  placeholder="Cash Type">
              </ejs-dropdownlist>
            </div>
            <div class="eff-modal-cell eff-trade-ticker-section">
              <div class="eff-trade-ticker">
                <ejs-textbox
                    v-model:value="ticker_symbol"
                    floatLabelType="Always"
                    placeholder="Ticker"
                    style="text-transform: uppercase"
                    @blur="lookupSymbol">
                </ejs-textbox>
              </div>
              <div v-if="ticker_found" class="eff-trade-ticker-checkmark">
                <img alt="check" :src="checkMark" style="height: 20px">
              </div>
            </div>
          </div>
          <div class="eff-modal-row">
            <div class="eff-modal-cell-pad eff-trade-date-picker">
              <ejs-datepicker
                  v-model:value="trade_date"
                  time-zone="false"
                  floatLabelType="Always"
                  placeholder="Date">
              </ejs-datepicker>
            </div>
            <div class="eff-modal-cell eff-trade-price">
              <ejs-numerictextbox
                  id="currency"
                  :min=0
                  format="c2"
                  :showSpinButton=false
                  v-model:value="trade_price"
                  floatLabelType="Always"
                  placeholder="Amount">
              </ejs-numerictextbox>
            </div>
          </div>
        </div>
        <ejs-tab
            id="tab_trades"
            heightAdjustMode="None"
            showCloseButton=false
            :selected="tabSelected"
            style="transition: None"
        >
          <e-tabitems>
            <e-tabitem :header='headerText0' content="#stocks"></e-tabitem>
            <e-tabitem :header='headerText1' content="#options"></e-tabitem>
            <e-tabitem :header='headerText2' content="#cash"></e-tabitem>
          </e-tabitems>
        </ejs-tab>
      </slot>
    </section>
    <div class="eff-modal-footer">
      <div class="eff-modal-footer-right">
        <button
            type="button"
            class="eff-btn-blue"
            @click="save">
          Save
        </button>
        <button
            type="button"
            class="eff-btn-blue"
            @click="close">
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import {TabComponent, TabItemDirective, TabItemsDirective} from "@syncfusion/ej2-vue-navigations";
import checkMark from "@/assets/img/circle-check-solid.svg";
import axios from "axios";

export default {
  name: "TradeCreateModal",
  props: {
      modal_name: String,
      selected_account_id: Number,
  },
  emits: ['close-modal'],
  components: {
    Modal,
    "ejs-tab": TabComponent,
    "e-tabitems": TabItemsDirective,
    "e-tabitem": TabItemDirective
  },
  data() {
    return {
      checkMark,
      active_tab: 'stocks',
      stock_trade_types: ['Buy', 'Sell'],
      option_trade_map: [
        { key: 'Buy', action: 'Buy to Open' },
        { key: 'Short', action: 'Sell to Open' },
        { key: 'Sell', action: 'Sell to Close' },
        { key: 'Cover', action: 'Buy to Close' }
      ],
      option_type: 'Call',
      option_types: ['Call', 'Put'],
      option_expiration_date: '',
      option_expiration_dates: [],
      option_strike_price: '',
      option_strike_prices: [],
      cash_type: '',
      cash_types: ['Deposit', 'Withdrawal', 'Interest Accrued', 'Interest Paid', 'Fee', 'Fee Adjust', 'Dividend', 'Equity Adjust', 'Rollover'],
      ticker_found: false,
      ticker_symbol: '',
      ticker_id: 0,
      option_id: 0,
      trade_date: new Date().toISOString(),
      trade_quantity: 0,
      trade_price: 0,
      trade_commission: 0,
      trade_action: 'Buy',
      headerText0: {'text': 'Stocks'},
      headerText1: {'text': 'Options'},
      headerText2: {'text': 'Cash'}
    }
  },
  methods: {
    tabSelected(arg) {
      if (arg.selectedIndex === 0) {
        this.active_tab = 'stocks';
      }
      if (arg.selectedIndex === 1) {
        this.active_tab = 'options';
      }
      if (arg.selectedIndex === 2) {
        this.active_tab = 'cash';
      }
      this.clearFields();
    },
    clearFields() {
      this.option_type = 'Call';
      this.option_expiration_date = '';
      this.option_expiration_dates = [];
      this.option_strike_price = '';
      this.option_strike_prices = [];
      this.cash_type = '';
      this.ticker_found = false;
      this.ticker_symbol = '';
      this.ticker_id = 0;
      this.option_id = 0;
      this.trade_date = new Date().toISOString();
      this.trade_quantity = 0;
      this.trade_price = 0;
      this.trade_commission = 0;
      this.trade_action = 'Buy';
    },
    getStrikePrices() {
      if (this.active_tab === 'options' && this.ticker_id > 0 && this.option_expiration_date !== '') {
        axios
            .get(process.env.VUE_APP_BASE_URL + '/options/getstrikes', {
              params: {
                ticker_id: this.ticker_id,
                expiration_date: this.option_expiration_date
              }
            })
            .then(response => {
              this.option_strike_prices = response.data
            })
      }
    },
    getOptionDates() {
      this.option_strike_prices = []
      this.option_strike_price = ''
      if (this.active_tab === 'options' && this.ticker_id > 0) {
        axios
            .get(process.env.VUE_APP_BASE_URL+'/options/getdates', {
              params: {
                ticker_id: this.ticker_id
              }
            })
            .then(response => {
              this.option_expiration_dates = response.data
            })
      }
    },
    lookupSymbol() {
      this.ticker_found = false
      this.ticker_id = 0
      this.option_expiration_dates = []
      this.option_expiration_date = ''
      this.option_strike_prices = []
      this.option_strike_price = ''
      if (this.ticker_symbol.length > 0) {
        axios
            .get(process.env.VUE_APP_BASE_URL+'/markets/getbysymbol', {
              params: { ticker_symbol: this.ticker_symbol }
            })
            .then(response => {
                if (Number(response.data.ticker_id) > 0) {
                  this.ticker_id = response.data.ticker_id;
                  this.ticker_found = true;
                }
                if (this.active_tab === 'options') {
                  this.getOptionDates();
                }
            })
            .catch(err => {
              if (err.response.status === 400) {
                this.ticker_found = false;
              }
            })
      }
      else {
        this.ticker_found = false
      }
    },
    lookupOption() {
      if (this.ticker_id > 0 && this.option_expiration_date !== '' && this.option_strike_price !== '') {
        axios
            .get(process.env.VUE_APP_BASE_URL + '/options/getid', {
              params: {
                ticker_id: this.ticker_id,
                option_type: this.option_type,
                expiration_date: this.option_expiration_date,
                strike_price: this.option_strike_price
              }
            })
            .then(response => {
              this.option_id = response.data.option_id
            })
      }
    },
    save() {
      if (this.active_tab === 'stocks') {
        axios
            .post(process.env.VUE_APP_BASE_URL+'/stocktrades/create', {
              ticker_symbol: this.ticker_symbol,
              account_id: this.selected_account_id,
              trade_date: this.trade_date.toLocaleString(),
              trade_quantity: this.trade_quantity,
              trade_action: this.trade_action,
              trade_price: this.trade_price,
              trade_commission: this.trade_commission,
              trade_match_type: 'Tax Advantage'
            }).then(() => {
              this.close();
          }
        )
      }
      if (this.active_tab === 'options') {
        axios
            .post(process.env.VUE_APP_BASE_URL+'/optiontrades/create', {
              ticker_symbol: this.ticker_symbol,
              option_type: this.option_type,
              expiration_date: this.option_expiration_date,
              strike_price: this.option_strike_price,
              account_id: this.selected_account_id,
              trade_date: this.trade_date.toLocaleString(),
              trade_contracts: this.trade_quantity,
              trade_action: this.trade_action,
              trade_price: this.trade_price,
              trade_commission: this.trade_commission,
              trade_match_type: 'Tax Advantage'
            }).then(() => {
              this.close();
          }
        )
      }
      if (this.active_tab === 'cash') {
        axios
            .post(process.env.VUE_APP_BASE_URL + '/cashtrades/create', {
              account_id: this.selected_account_id,
              ticker_symbol: this.ticker_symbol,
              adjustment_date: this.trade_date.toLocaleString(),
              adjustment_type: this.cash_type,
              adjustment_value: this.trade_price
            }).then(() => {
              this.close();
            }
        )
      }
    },
    close() {
      this.active_tab = 'stocks';
      this.$emit("close-modal")
      this.clearFields();
      this.$store.dispatch('modals/close', this.$props['modal_name'])
    },
  },
};
</script>

<style>
@import '../assets/styles/trade.css';
</style>